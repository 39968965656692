<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    ></b-pagination>
    <b-modal 
    id="modal-clinica" 
    size="lg"
    title="Cadastrar/Editar Clínica"
    no-fade
    content-class="modalDireito">
      <clinicas-form
        ref="clinicaForm"
        :clinica_edit="clinica_edit"
        :onSaveDone="onSaveDone"
      />
      <template #modal-footer>
        <b-button variant="primary" @click="salvar"
          ><i class="fa fa-save"></i> Salvar</b-button
        >
        <b-button variant="secondary" @click="endEdit()"
          ><i class="fa fa-times"></i> Cancelar</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import ClinicasLib from "@/libs/ClinicasLib";
import ClinicasForm from "./ClinicasForm.vue";
export default {
  components: { ClinicasForm },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "sigla", label: "Sigla", sortable: true },
        { key: "Empresa", label: "Empresa", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      clinica_edit: null,
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      this.loading = true;
      //agurada atualiza os componentes da tela
      await this.$nextTick();

      this.lista = await ClinicasLib.get();

      this.loading = false;
    },
    async novo() {
      this.$bvModal.show("modal-clinica");
    },
    salvar() {
      this.$refs.clinicaForm.salvar();
    },
    onSaveDone() {
      this.carregar();
      this.$bvModal.hide("modal-clinica");
    },
    async excluir(clinica) {
      if (clinica) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir esta clínica?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const res = await ClinicasLib.del({ id: clinica.id });
              if (res && res.success) {
                this.$swal
                  .fire({
                    title: "Clínica excluída com sucesso!",
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  })
                  .then(() => {
                    this.carregar();
                  });
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir clínica!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
    async editar(clinica) {
      if (clinica) {
        console.log(clinica);
        this.clinica_edit = clinica;
        this.$bvModal.show("modal-clinica");
      }
    },
    endEdit(){
          this.$bvModal.hide('modal-clinica')
          this.clinica_edit = null
        }
  },
};
</script>

<style>
</style>