<template>
  <div>
    <div class="row">
      <div class="col-12">
        <label>Nome</label>
        <b-input
          type="text"
          v-model="clinica.nome"
          placeholder="Nome da cínica"
          :state="validateState('nome')"
        />
        <b-form-invalid-feedback
          >Informe um nome válido</b-form-invalid-feedback
        >
      </div>
      <div class="col-12">
        <label>Sigla</label>
        <b-input
          type="text"
          v-model="clinica.sigla"
          placeholder="Sigla da cínica"
          :state="validateState('sigla')"
        />
        <b-form-invalid-feedback
          >Informe uma sigla válida</b-form-invalid-feedback
        >
      </div>
      <div class="col-12">
        <label>Empresa</label>
        <b-select
          :options="empresas"
          v-model="clinica.empresa_id"
          placeholder="Empresa"
          :state="validateState('empresa_id')"
        />
        <b-form-invalid-feedback
          >Informe uma empresa válida
          </b-form-invalid-feedback
        >
      </div>
    </div>
  </div>
</template>

<script>
import Validadores from "@/helpers/Validadores";
import EmpresasLib from "@/libs/EmpresasLib";
import ClinicasLib from "@/libs/ClinicasLib";
export default {
  props: {
    onSaveDone: Function,
    clinica_edit: Object,
  },
  data() {
    return {
      clinica: {
        nome: "",
        sigla: "",
        empresa_id: 0,
      },
      hasErrors: [],
      empresas: [],
    };
  },
  async mounted() {
    this.clearErrors();

    this.empresas = await EmpresasLib.getEmpresasGrupo();

    if (this.empresas && this.empresas.length > 0) {
      this.empresas = this.empresas.map((item) => {
        return {
          value: item.id,
          text: `${item.sigla} - ${item.razao_social}`,
        };
      });
    }

    // console.log("empresas", this.empresas);
    if (this.clinica_edit) {
      this.clinica = this.clinica_edit;
    }
  },

  methods: {
    async editar(clinica) {
      this.clinica = clinica;
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();

      this.addOrUpdateError(
        "nome",
        !Validadores.string.IsNullOrEmpty(this.clinica.nome)
      );
      this.addOrUpdateError(
        "sigla",
        !Validadores.string.IsNullOrEmpty(this.clinica.sigla)
      );
      this.addOrUpdateError("empresa_id", this.clinica.empresa_id > 0);

      await this.$nextTick();
      console.log(
        "resultado da validacao",
        this.hasErrors.filter((x) => x.state == true).length >= 3
      );
      return this.hasErrors.filter((x) => x.state == true).length >= 3;
    },
    showErrorSave() {
      this.$swal.fire({
        title: "Erro ao salvar!",
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
    async salvar() {
      try {
        const validado = await this.validar();
        console.log("go store?", validado);
        if (validado === true) {
          console.log("objeto mandado", this.clinica);
          const res = await ClinicasLib.store(this.clinica);
          console.log("resultado store", res);
          if (res && res.success) {
            this.$swal.fire({
              title: "Concluído!",
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            });
            if (this.onSaveDone) {
              this.onSaveDone();
            }
          } else {
            this.showErrorSave();
          }
        }
      } catch (e) {
        console.log("error on save", e);
        this.showErrorSave();
      }
    },
  },
};
</script>

<style>
</style>